<template>
  <main class="container">
    <div class="row">
      <div class="content col-md-12">
        <h1>Tools for app developers</h1>
        <h2>
          Don't Go Ape. Go Ape Tools. For when it all seems like too much work.
        </h2>
        <h3>Image Gorilla</h3>
        <p>
          Generate your App Icons and Splashscreens in all the required sizes to
          get your app to market faster.
        </p>
        <address>
          <strong>Questions:</strong>
          <a href="https://github.com/webprofusion/apetools"
            >See our GitHub page</a
          >
        </address>
      </div>
    </div>
  </main>
</template>
